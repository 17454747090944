import React, { Fragment } from 'react';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { version } from '../../../constants';

export default function About() {
  const aboutSections = [
    {
      header: 'Disclaimer',
      body: `This domain, the app, and everything you're looking at here has no affiliation with Caltrain. This app exists because an independent party riding the caltrain wanted a quick view for figuring out the caltrain schedule, without attempting to navigate caltrain.com on mobile.`,
      subsections: [
        {
          header: 'Breakages',
          body: `The creator of this app is a regular rider of the caltrain. If the app breaks I will attempt to fix it.. when I notice. Maybe like a weekend or two after, depends if I'm busy with my actual full time job. I don't have a way to report bugs right now, so.. sorry. Just cross your fingers, pray it doesn't break, and enjoy the mobile friendly view.`,
        },
      ],
    },
    {
      header: 'Train Data',
      body: (
        <React.Fragment>
          The way this page works is by parsing the{' '}
          <Link href="https://www.caltrain.com/schedules/weekend-timetable.html">
            weekend schedule
          </Link>
          ,{' '}
          <Link href="https://www.caltrain.com/schedules/weekdaytimetable.html">
            weekday schedule
          </Link>
          , and{' '}
          <Link href="https://www.caltrain.com/schedules/modified_schedule.html">
            modified scheduled
          </Link>
          . In addition, the site attempts to parse the{' '}
          <Link href="https://www.caltrain.com/schedules/holidayservice.html">
            holiday schedule page
          </Link>
          , and the{' '}
          <Link href="https://www.caltrain.com/schedules/realtime.html">
            real-time departures page
          </Link>
          . All of this data is pulled together in a best attempt to show the
          trains that are going from the selected station -&gt; destination
          station. It should work, including for extra trains not on the
          schedule, like the train that goes to Stanford on football days. But
          if it doesn't work... I'm sorry, I tried.
        </React.Fragment>
      ),
    },
    {
      header: 'Privacy Policy',
      body: (
        <React.Fragment>
          This page exists as a single page app, with no user data stored or
          sent to the servers that run this app. There's no database, no cookie
          usage, all the data used for this website is pulled from{' '}
          <Link href="https://www.caltrain.com">caltrain.com</Link>, and all
          user data (settings) are stored in your browser, and aren't
          transffered out of your browser.
        </React.Fragment>
      ),
    },
    {
      header: 'Third Parties',
      body: (
        <React.Fragment>
          This app uses many third party technologies to come into existence. I
          don't know if I have to list all of them out, this section exists
          because I want to call out the ones I found esspecially helpful. I
          appreciate these things that help make this app possibe:
          <List>
            <ListItem>
              <ListItemText>
                <Link href="https://m.do.co/c/d9101d673e50">DigitalOcean</Link>:
                Using DigitalOcean Apps (and thank you also for all the great
                documentation).
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link href="https://mui.com">Material UI (MUI)</Link>: All the
                frontend uses Material UI. I chose Material UI because I like
                the visual appearance, and I most appreciate that it supports
                dark theme, and light theme, because I want my precious users to
                use whatever they want.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link href="https://github.com/twitter/twemoji">Twemoji</Link>:
                For being incredible, free, and hosted on{' '}
                <Link href="https://www.maxcdn.com">MaxCDN</Link>. These are my
                favorite emojis, and I apprecaite how easy it was for me to
                integrate emojis into this site.
                <br />
                TODO: Emojis all over this page.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                My friends Andrea (who helped me with the frontend design), and
                Erica (who made the favicon).
              </ListItemText>
            </ListItem>
          </List>
        </React.Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <List>
        {aboutSections.map((section) => {
          return (
            <ListItem>
              <Box>
                <Typography variant="h5" component="h1">
                  {section.header}
                </Typography>
                <Typography variant="body2">{section.body}</Typography>
                {section.subsections?.map((subsection) => {
                  return (
                    <React.Fragment>
                      <Typography variant="h6" component="h2" sx={{ mt: 1 }}>
                        {subsection.header}
                      </Typography>
                      <Typography variant="body2">{subsection.body}</Typography>
                    </React.Fragment>
                  );
                })}
              </Box>
            </ListItem>
          );
        })}
      </List>
      <Typography variant="caption" sx={{ padding: 1 }}>
        Version: {version}
      </Typography>
    </Fragment>
  );
}
