import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Alert, Box, CircularProgress } from '@mui/material';
import { pages } from '../../constants';
import {
  getDefaultStartPoint,
  getDefaultDestination,
} from '../../services/settingsService';
import { getStationNames } from '../../services/stationService';
import Header from '../components/header';
import Schedule from './schedule';
import Settings from './settings';
import Train from './train';
import NotFound from './not-found';
import About from './about';

const getDefaultDate = () => {
  const date = new Date();
  if (date.getHours() < 3) {
    // It's before 3 AM.. we could be looking for trains from the previous day.
    return new Date(date.getTime() - 6 * 60 * 60 * 1000);
  }

  return date;
};

export default function PageContainer({}) {
  const [date, setDate] = useState(getDefaultDate());
  const [startPoint, setStartPoint] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [stations, setStations] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getStationNames()
      .then(async (stationNames) => {
        const defaultStartPoint = await getDefaultStartPoint();
        setStartPoint(defaultStartPoint);

        const defaultDestination = await getDefaultDestination();
        setDestination(defaultDestination);

        setStations(stationNames);
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage(
          'Failed to load Caltrain stations. Please refresh to try again.'
        );
      });
  }, []);

  const renderPageContents = () => {
    if (errorMessage) {
      return (
        <Alert severity="error" className="site-error-message">
          {errorMessage}
        </Alert>
      );
    }

    if (stations.length < 1) {
      return <CircularProgress className="loading-indicator" size={80} />;
    }

    return (
      <Routes>
        <Route
          path={pages.schedule.path}
          element={
            <Schedule
              date={date}
              destination={destination}
              startPoint={startPoint}
            />
          }
        />
        <Route path={pages.settings.path} element={<Settings />} />
        <Route path={pages.train.path} element={<Train />} />
        <Route path={pages.about.path} element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  };

  return (
    <Box>
      <Header
        date={date}
        destination={destination}
        menuOpen={menuOpen}
        setDate={setDate}
        setDestination={setDestination}
        setMenuOpen={setMenuOpen}
        setStartPoint={setStartPoint}
        startPoint={startPoint}
        stations={stations}
      />
      {renderPageContents()}
    </Box>
  );
}
