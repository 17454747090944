import { appConfiguration, urls } from '../constants';
import TrainType from '../enums/TrainType';
import Train from '../types/train';
import TrainSchedule from '../types/trainSchedule';

let trainCache: { [trainNumber: number]: Train } = {};
let scheduleCache: { [date: string]: TrainSchedule } = {};

const parseTrain = (trainResult: any): Train => {
  const train = {
    number: trainResult.number,
    type: trainResult.type as TrainType,
    stops: trainResult.stops.map((stop: any) => {
      return {
        station: stop.station,
        scheduledDepartureTime: stop.scheduledDepartureTime,
        scheduledArrivalTime: stop.scheduledArrivalTime,
        minutesLate: stop.minutesLate,
        estimatedDepartureTime: stop.estimatedDepartureTime,
        estimatedArrivalTime: stop.estimatedArrivalTime,
        estimatedDepartureDate: stop.estimatedDepartureDate
          ? new Date(stop.estimatedDepartureDate)
          : undefined,
        estimatedArrivalDate: stop.estimatedArrivalDate
          ? new Date(stop.estimatedArrivalDate)
          : undefined,
      };
    }),
  };

  return (trainCache[train.number] = train);
};

const getTrainSchedule = async (date: Date): Promise<TrainSchedule> => {
  const dateString = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;
  if (scheduleCache[dateString]) {
    return scheduleCache[dateString];
  }

  const response = await fetch(
    `${urls.apiOrigin}/v1/caltrain/schedule?date=${dateString}`
  );
  const result = await response.json();

  return (scheduleCache[dateString] = {
    trains: result.data.trains.map(parseTrain),
  });
};

const getTrainByTrainNumber = async (
  trainNumber: number
): Promise<Train | undefined> => {
  const cachedTrain = trainCache[trainNumber];
  if (cachedTrain) {
    return cachedTrain;
  }

  const response = await fetch(
    `${urls.apiOrigin}/v1/caltrain/trains/${trainNumber}`
  );
  const result = await response.json();
  if (result.data) {
    return parseTrain(result.data);
  }

  return undefined;
};

const refreshActiveTrains = () => {
  fetch(`${urls.apiOrigin}/v1/caltrain/trains/active`)
    .then((response) => {
      response
        .json()
        .then((result) => {
          // Update the cache with these active trains.
          result.data.forEach(parseTrain);
        })
        .catch((err) => {
          console.warn('Failed to parse active train data', err);
        });
    })
    .catch((err) => {
      console.warn('Failed to update active train data', err);
    });
};

refreshActiveTrains();
setInterval(refreshActiveTrains, appConfiguration.realTimeInterval);

export { getTrainSchedule, getTrainByTrainNumber };
