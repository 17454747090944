import { Fragment, useEffect, useState } from 'react';
import { appConfiguration } from '../../../constants';
import { getTrainByTrainNumber } from '../../../services/trainService';
import TrainStop from '../../../types/trainStop';
import { renderTime } from '../../../utils/timeUtils';

// Input for the TrainTimeLabel component.
type DepartureTimeLabelInput = {
  // The train number to display the time for.
  trainNumber: number;

  // The name of the station the train is starting at.
  startStationName: string;

  // The name of the station the train is going to.
  destinationStationName: string;
};

export default function TrainTimeLabel({
  trainNumber,
  startStationName,
  destinationStationName,
}: DepartureTimeLabelInput) {
  const [startTrainStop, setStartTrainStop] = useState<TrainStop | undefined>(
    undefined
  );
  const [destinationTrainStop, setDestinationTrainStop] = useState<
    TrainStop | undefined
  >(undefined);

  useEffect(() => {
    const update = () => {
      getTrainByTrainNumber(trainNumber)
        .then((train) => {
          if (train) {
            setStartTrainStop(
              train.stops.find((s) => s.station === startStationName)
            );
            setDestinationTrainStop(
              train.stops.find((s) => s.station === destinationStationName)
            );
          } else {
            setStartTrainStop(undefined);
            setDestinationTrainStop(undefined);
          }
        })
        .catch((err) => {
          console.warn(
            'TrainTimeLabel: Failed to fetch active train\n\tTrain Number:',
            trainNumber,
            err
          );
        });
    };

    update();

    const interval = setInterval(update, appConfiguration.realTimeInterval);
    return () => clearInterval(interval);
  }, [trainNumber, startStationName, destinationStationName]);

  if (!startTrainStop || !destinationTrainStop) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {renderTime(
        startTrainStop?.estimatedArrivalTime ||
          startTrainStop?.scheduledArrivalTime,
        destinationTrainStop?.estimatedArrivalTime ||
          destinationTrainStop?.scheduledArrivalTime
      )}
    </Fragment>
  );
}
