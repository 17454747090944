import { Menu, SwapHoriz } from '@mui/icons-material';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { application, pages } from '../../../constants';
import DatePicker from '../date-picker';
import StationSelect from '../station-select';
import Twemoji from '../twemoji';
import Navigation from './Navigation';

export default function Header({
  date,
  destination,
  menuOpen,
  setDate,
  setDestination,
  setMenuOpen,
  setStartPoint,
  startPoint,
  stations,
}) {
  const theme = useTheme();
  const location = useLocation();
  const [page, setPage] = useState(pages.schedule);

  const swapStationsClicked = () => {
    setStartPoint(destination);
    setDestination(startPoint);
  };

  const parseText = (page, text) => {
    const pathMatch = location.pathname.match(page.pathRegex);

    return text.replace(/{(\w+)}/g, (m) => {
      const key = m.substring(1, m.length - 1);
      const matchIndex = page.params && page.params[key];
      if (matchIndex && pathMatch && pathMatch[matchIndex]) {
        return pathMatch[matchIndex];
      }

      return '?';
    });
  };

  const getPage = () => {
    for (let pageType in pages) {
      const p = pages[pageType];
      if (p === pages.notFound) {
        continue;
      }

      if (location.pathname.match(p.pathRegex)) {
        return p;
      }
    }

    return pages.notFound;
  };

  const renderDatePicker = () => {
    if (!page.displayDate) {
      return;
    }

    return <DatePicker date={date} setDate={page.setDate && setDate} />;
  };

  useEffect(() => {
    const title = document.querySelector('title');
    const currentPage = getPage();

    title.innerText = `${parseText(currentPage, currentPage.title)} - ${
      application.name
    }`;

    setPage(currentPage);
  }, [location]);

  return (
    <AppBar position="sticky" className="navigation-bar">
      <Navigation menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          onClick={(e) => setMenuOpen(!menuOpen)}
        >
          <Menu />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Twemoji keyword={page.headerEmoji} />
          {' ' + parseText(page, page.headerText)}
        </Typography>
        {renderDatePicker()}
      </Toolbar>
      {page === pages.schedule &&
        startPoint &&
        destination &&
        stations.length > 0 && (
          <Grid
            container
            style={{
              padding: theme.spacing(0, 1),
            }}
            display="flex"
            justifyContent="center"
            flexDirection="row"
          >
            <Grid item xs={5}>
              <StationSelect
                label="Start Point"
                station={startPoint}
                stations={stations}
                setStation={setStartPoint}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="center">
                <IconButton size="large" onClick={swapStationsClicked}>
                  <SwapHoriz />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <StationSelect
                label="Destination"
                station={destination}
                stations={stations}
                setStation={setDestination}
              />
            </Grid>
          </Grid>
        )}
    </AppBar>
  );
}
