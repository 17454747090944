// 3 hour delay to take away from times, before comparing.
const dayLeewayInMilliseconds = 3 * 60 * 60 * 1000;
const timeRegex = /(\d+):(\d+)\s*(\w*)/;

const plural = (n: number) => {
  return n === 1 || n === -1 ? '' : 's';
};

const getRelativeTimeLabel = (minutes: number) => {
  if (minutes >= 120) {
    const hours = Math.floor(minutes / 60);
    return `${hours} hour${plural(hours)}`;
  }

  return `${minutes} minute${plural(minutes)}`;
};

const subtractLeeway = (time: string): Date => {
  const timeMatch = time.match(timeRegex);
  if (!timeMatch) {
    return new Date(0);
  }

  let hours = Number(timeMatch[1]);
  const minutes = Number(timeMatch[2]);
  const timeSuffix = timeMatch[3].toUpperCase();

  if (timeSuffix === 'PM' && hours !== 12) {
    hours += 12;
  } else if (timeSuffix === 'AM' && hours === 12) {
    hours = 0;
  }

  // https://stackoverflow.com/a/6427318/1663648
  const original = new Date(1985, 2, 2, hours, minutes);
  const leeweighed = new Date(original.getTime() - dayLeewayInMilliseconds);
  return new Date(
    original.getFullYear(),
    original.getMonth(),
    original.getDate(),
    leeweighed.getHours(),
    leeweighed.getMinutes()
  );
};

const timeComparer = (aTime: string, bTime: string): number => {
  const a = subtractLeeway(aTime);
  const b = subtractLeeway(bTime);

  if (a.getTime() === b.getTime()) {
    return 0;
  }

  return a.getTime() < b.getTime() ? -1 : 1;
};

const getTimeTakenInMilliseconds = (aTime: string, bTime: string): number => {
  const a = subtractLeeway(aTime);
  const b = subtractLeeway(bTime);
  return b.getTime() - a.getTime();
};

const renderTime = (aTime: string, bTime: string): string => {
  const timeInSeconds = getTimeTakenInMilliseconds(aTime, bTime) / 1000;
  let timeInMinutes = Math.floor(timeInSeconds / 60);
  let times = [];

  if (timeInMinutes >= 60) {
    const hours = Math.floor(timeInMinutes / 60);
    timeInMinutes -= hours * 60;
    times.push(`${hours}h`);
  }

  if (timeInMinutes > 0) {
    times.push(`${timeInMinutes}m`);
  }

  return times.join(' ');
};

export { getRelativeTimeLabel, renderTime, timeComparer };
