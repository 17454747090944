import emojis from 'emojilib';
import { useEffect, useState } from 'react';
import { parse as parseTwemoji } from 'twemoji-parser';

const emojisByKeyword = (window.emojisByKeyword = {});
window.emojis = emojis;

Object.keys(emojis).forEach((emojiCharacter) => {
  emojis[emojiCharacter].forEach((keyword) => {
    emojisByKeyword[keyword] = emojiCharacter;
  });
});

const getTwemojiByKeyword = (keyword) => {
  const emoji = keyword && emojisByKeyword[keyword];
  if (emoji) {
    const twemojis = parseTwemoji(emoji);
    return twemojis[0];
  }

  return null;
};

export default function Twemoji({ keyword }) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const emoji = getTwemojiByKeyword(keyword);
    setSrc(emoji?.url);
  }, [keyword]);

  if (src) {
    return (
      <img
        className="emoji"
        alt={keyword}
        src={src}
        onError={() => setSrc('')}
        draggable={false}
      />
    );
  }

  return emojisByKeyword[keyword];
}
