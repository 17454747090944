import { urls } from '../constants';
import Station from '../types/station.js';

let stationCache: Station[] | null = null;

const getStations = async (): Promise<Station[]> => {
  if (stationCache) {
    return stationCache;
  }

  const response = await fetch(`${urls.apiOrigin}/v1/caltrain/stations`);
  const result = await response.json();
  return (stationCache = result.data);
};

const getStationNames = async (): Promise<string[]> => {
  const stations = await getStations();
  return stations.map((s) => s.name);
};

export { getStations, getStationNames };
