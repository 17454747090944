import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default function StationSelect({
  label,
  station,
  stations,
  setStation,
  sx,
}) {
  return (
    <FormControl sx={sx} fullWidth className="station-selection-form">
      <InputLabel
        id="station-selection-label"
        style={{ transform: 'scale(0.75)' }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="station-selection-label"
        fullWidth
        IconComponent={null}
        value={station}
        onChange={(e) => setStation(e.target.value)}
        variant="standard"
      >
        {stations.map((stationName) => {
          return (
            <MenuItem key={stationName} value={stationName}>
              {stationName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
