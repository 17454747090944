import { Avatar } from '@mui/material';

type StationCardAvatarInput = {
  stopLetter: string;
};

export default function StationCardAvatar({
  stopLetter,
}: StationCardAvatarInput) {
  const color = stopLetter === 'A' ? 'primary.main' : 'secondary.dark';
  return (
    <Avatar className="station-card-avatar" sx={{ bgcolor: color }}>
      {stopLetter}
    </Avatar>
  );
}
