import React, { useEffect, useState } from 'react';
import { appConfiguration } from '../../../constants';
import { getTrainByTrainNumber } from '../../../services/trainService';
import TrainStop from '../../../types/trainStop';

// Input for the DepartureTimeLabel component.
type DepartureTimeLabelInput = {
  // The train number to display the departure time for.
  trainNumber: number;

  // The name of the station related to the departure time.
  stationName: string;
};

export default function DepartureTimeLabel({
  trainNumber,
  stationName,
}: DepartureTimeLabelInput) {
  const [trainStop, setTrainStop] = useState<TrainStop | undefined>(undefined);

  useEffect(() => {
    const update = () => {
      getTrainByTrainNumber(trainNumber)
        .then((train) => {
          if (train) {
            setTrainStop(train.stops.find((s) => s.station === stationName));
          } else {
            setTrainStop(undefined);
          }
        })
        .catch((err) => {
          console.warn(
            'DepartureTimeLabel: Failed to fetch active train\n\tTrain Number:',
            trainNumber,
            err
          );
        });
    };

    update();

    const interval = setInterval(update, appConfiguration.realTimeInterval);
    return () => clearInterval(interval);
  }, [trainNumber, stationName]);

  if (!trainStop) {
    return <React.Fragment />;
  }

  return (
    <span
      className={
        `train-departure-time-label` +
        (trainStop.minutesLate && trainStop.minutesLate >= 2
          ? ' train-late'
          : '')
      }
    >
      <span className="scheduled-departure-time">
        {trainStop.scheduledDepartureTime}
      </span>
      <span className="estimated-departure-time">
        {trainStop.estimatedDepartureTime}
      </span>
    </span>
  );
}
