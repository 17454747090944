import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageContainer from '../../pages';

export default function App() {
  const useLightTheme = useMediaQuery('(prefers-color-scheme: light)');
  const theme = createTheme({
    palette: {
      mode: useLightTheme ? 'light' : 'dark',
      primary: {
        main: '#e53935',
      },
      secondary: {
        main: useLightTheme ? '#212121' : '#fafafa',
      },
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PageContainer />
      </ThemeProvider>
    </BrowserRouter>
  );
}
