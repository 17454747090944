import { DateRange, Info, Settings } from '@mui/icons-material';
export { default as version } from './version';

const application = {
  name: 'caltrain.app',
};

const appConfiguration = {
  // How often to refresh real time train data.
  realTimeInterval: 15 * 1000,

  // How long to keep a train on the schedule page after it's left the station.
  scheduleTrainMaxAge: 2 * 60 * 1000,
};

const pages = {
  about: {
    headerText: 'About',
    headerEmoji: 'scroll',
    navigationIcon: <Info />,
    path: '/about',
    pathRegex: /^\/about$/,
    title: 'About',
  },

  schedule: {
    headerText: 'Schedule',
    headerEmoji: 'bullet_train',
    navigationIcon: <DateRange />,
    displayDate: true,
    setDate: true,
    path: '/',
    pathRegex: /^\/$/,
    title: 'Schedule',
  },

  settings: {
    headerText: 'Settings',
    headerEmoji: 'gear',
    navigationIcon: <Settings />,
    path: '/settings',
    pathRegex: /^\/settings$/i,
    title: 'Settings',
  },

  train: {
    headerText: 'Train #{trainNumber}',
    headerEmoji: 'station',
    path: '/trains/:trainNumber',
    pathRegex: /^\/trains\/(\d+)$/,
    params: {
      trainNumber: 1,
    },
    title: 'Train #{trainNumber}',
  },

  notFound: {
    headerText: '?',
    headerEmoji: 'person_shrugging',
    path: '/not-found',
    pathRegex: /.*/,
    title: '404',
  },
};

const urls = {
  apiOrigin: window.location.host.startsWith('localhost')
    ? 'https://caltrain.app/api'
    : `${window.location.origin}/api`,
};

export { appConfiguration, application, pages, urls };
