import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { appConfiguration } from '../../../constants';
import { getTrainByTrainNumber } from '../../../services/trainService';
import { getRelativeTimeLabel } from '../../../utils/timeUtils';

// Input for the LateChip component.
type LateChipInput = {
  // The train number to display the late chip for.
  trainNumber: number;

  // The name of the station related to the late chip.
  stationName: string;
};

export default function LateChip({ trainNumber, stationName }: LateChipInput) {
  const [minutesLate, setMinutesLate] = useState<number>(0);

  useEffect(() => {
    const update = () => {
      getTrainByTrainNumber(trainNumber)
        .then((train) => {
          if (!train) {
            setMinutesLate(0);
            return;
          }

          const stop = train.stops.find((s) => s.station === stationName);
          setMinutesLate(stop?.minutesLate || 0);
        })
        .catch((err) => {
          console.warn(
            `LateChip: Failed to fetch active train\n\tTrain Number:`,
            trainNumber,
            err
          );
        });
    };

    update();

    const interval = setInterval(update, appConfiguration.realTimeInterval);
    return () => clearInterval(interval);
  }, [trainNumber, stationName]);

  if (minutesLate >= 2) {
    return (
      <Chip
        className="late-chip"
        color="warning"
        label={`${getRelativeTimeLabel(minutesLate)} late`}
        variant="outlined"
        size="small"
      />
    );
  }

  return <React.Fragment />;
}
