import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Card,
  CardHeader,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import LateChip from '../../components/late-chip';
import DepartureTimeLabel from '../../components/departure-time-label';
import { appConfiguration } from '../../../constants';
import { getTrainByTrainNumber } from '../../../services/trainService';
import Train from '../../../types/train';
import TrainStop from '../../../types/trainStop';

const getCurrentStopIndex = (train: Train) => {
  const currentTime = +new Date();

  for (let i = 0; i < train.stops.length; i++) {
    const stop = train.stops[i];
    if (!stop.estimatedDepartureTime) {
      // Estimated times are always provided for live trains.
      // If the train is not live, no estimated time, no current stop index.
      return -1;
    }

    if (
      stop.estimatedArrivalDate &&
      stop.estimatedArrivalDate.getTime() > currentTime
    ) {
      return i - 1;
    }
  }

  return train.stops.length;
};

export default function TrainPage() {
  const { trainNumber } = useParams();
  const [train, setTrain] = useState<Train | undefined>(undefined);
  const [currentStopIndex, setCurrentStopIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const update = () => {
      getTrainByTrainNumber(Number(trainNumber))
        .then((t) => {
          setTrain(t);

          if (t) {
            setCurrentStopIndex(getCurrentStopIndex(t));
          } else {
            setCurrentStopIndex(-1);
          }
        })
        .catch((err) => {
          console.warn('Failed to load train information', err);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    update();
    const interval = setInterval(update, appConfiguration.realTimeInterval);
    return () => clearInterval(interval);
  }, [trainNumber]);

  if (loading) {
    return <CircularProgress className="loading-indicator" size={80} />;
  }

  if (!train) {
    return (
      <Alert severity="warning" className="site-error-message">
        The selected train is unrecognized. Not really sure how this happened,
        so sorry.
      </Alert>
    );
  }

  const getStationLabel = (stop: TrainStop) => {
    return (
      <React.Fragment>
        {stop.station}
        <LateChip trainNumber={train.number} stationName={stop.station} />
      </React.Fragment>
    );
  };

  return (
    <Stepper
      activeStep={currentStopIndex}
      className="train-stops-stepper"
      orientation="vertical"
      sx={{ p: 1 }}
    >
      {train.stops.map((stop) => {
        return (
          <Step>
            <Card sx={{ p: 0 }}>
              <CardHeader
                avatar={<StepLabel />}
                title={getStationLabel(stop)}
                subheader={
                  <DepartureTimeLabel
                    trainNumber={train.number}
                    stationName={stop.station}
                  />
                }
                sx={{ p: 1 }}
              />
            </Card>
          </Step>
        );
      })}
    </Stepper>
  );
}
