const tag = document.querySelector('script[defer][src]');
let version = 'unknown';

if (tag instanceof HTMLScriptElement) {
  const srcUrl = new URL(tag.src);
  const splitUrl = srcUrl.pathname.split('.');
  splitUrl.pop();
  version = splitUrl.pop() || version;
}

export default version;
