import React, { Fragment, useEffect, useState } from 'react';
import { Alert, CircularProgress, List, ListItem } from '@mui/material';
import TrainCard from '../../components/train-card';
import { appConfiguration } from '../../../constants';
import { getTrainSchedule } from '../../../services/trainService';
import Train from '../../../types/train';
import { timeComparer } from '../../../utils/timeUtils';

const isToday = (date: Date) => {
  const currentDate = +new Date();
  return date.getTime() < currentDate;
};

const getTrainDate = (date: Date, time: string) => {
  if (time.includes('AM')) {
    // Morning train.
    if (
      time.startsWith('12:') ||
      time.startsWith('1:') ||
      time.startsWith('2:') ||
      time.startsWith('3:')
    ) {
      // Yesterday's train.
      const nextDay = new Date(date.getTime() + 24 * 60 * 60 * 1000);
      return new Date(`${nextDay.toDateString()} ${time}`);
    }
  }

  return new Date(`${date.toDateString()} ${time}`);
};

const getTrainStop = (train: Train, stationName: string) => {
  for (let i = 0; i < train.stops.length; i++) {
    if (train.stops[i].station === stationName) {
      return train.stops[i];
    }
  }
};

const getTrainStopIndex = (train: Train, stationName: string): number => {
  for (let i = 0; i < train.stops.length; i++) {
    if (train.stops[i].station === stationName) {
      return i;
    }
  }

  return -1;
};

type SchedulePageInput = {
  startPoint: string;
  destination: string;
  date: Date;
};

export default function Schedule({
  startPoint,
  destination,
  date,
}: SchedulePageInput) {
  const [trains, setTrains] = useState<Train[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setTrains([]);
    setLoading(true);
    setErrorMessage('');

    const sortTrains = (a: Train, b: Train) => {
      const aStop = getTrainStop(a, startPoint);
      const bStop = getTrainStop(b, startPoint);
      const aTime = aStop?.estimatedArrivalTime || aStop?.scheduledArrivalTime;
      const bTime = bStop?.estimatedArrivalTime || bStop?.scheduledArrivalTime;
      return timeComparer(aTime || '', bTime || '');
    };

    const update = async () => {
      const today = isToday(date);

      try {
        const schedule = await getTrainSchedule(date);
        const relevantTrains = schedule.trains.filter((train) => {
          const startStopIndex = getTrainStopIndex(train, startPoint);
          const destinationIndex = getTrainStopIndex(train, destination);
          if (startStopIndex < 0 || destinationIndex < 0) {
            return false;
          }

          return startStopIndex <= destinationIndex;
        });

        if (today) {
          // Filter the trains down to the ones that are remaining for the day.
          const currentTime = +new Date();
          const remainingTrains = relevantTrains.filter((train: Train) => {
            const startPointStop = getTrainStop(train, startPoint);
            if (!startPointStop) {
              // Well this shouldn't be possible.
              return false;
            }

            const trainDate = getTrainDate(
              date,
              startPointStop.estimatedDepartureTime ||
                startPointStop.scheduledDepartureTime
            );
            return (
              trainDate.getTime() >
              currentTime - appConfiguration.scheduleTrainMaxAge
            );
          });

          setTrains(remainingTrains.sort(sortTrains));
        } else {
          setTrains(relevantTrains.sort(sortTrains));
        }

        setLoading(false);
      } catch (e) {
        if (today) {
          console.error(
            'Failed to load trains, will retry after real time interval...',
            e
          );
        } else {
          console.error('Failed to load train schedule', e);
          setErrorMessage('Failed to load trains. Do something to try again.');
          setLoading(false);
        }
      }
    };

    update();

    if (isToday(date)) {
      // If we're looking at today's schedule, make sure to start a loop
      // to keep the trains on the page up-to-date
      const updateInterval = setInterval(
        update,
        appConfiguration.realTimeInterval
      );
      return () => clearInterval(updateInterval);
    }
  }, [date, startPoint, destination]);

  if (loading) {
    return <CircularProgress className="loading-indicator" size={80} />;
  }

  if (errorMessage) {
    return (
      <Alert severity="error" className="site-error-message">
        {errorMessage}
      </Alert>
    );
  }

  if (trains.length < 1) {
    return (
      <Alert severity="warning" className="site-error-message">
        There are no trains available for your selected start point,
        destination, and date. Sorry!
      </Alert>
    );
  }

  return (
    <List>
      {trains.map((train) => {
        const arrivalStop = getTrainStop(train, startPoint);
        const destinationStop = getTrainStop(train, destination);
        if (!arrivalStop || !destinationStop) {
          // well this shouldn't be possible
          return <Fragment />;
        }

        return (
          <ListItem
            sx={{ p: 1 }}
            key={`${train.number}_${arrivalStop.scheduledArrivalTime}`}
          >
            <TrainCard
              startStop={arrivalStop}
              destinationStop={destinationStop}
              train={train}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
