import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import TrainType from '../../../enums/TrainType';
import Train from '../../../types/train';
import TrainStop from '../../../types/trainStop';
import DepartureTimeLabel from '../departure-time-label';
import LateChip from '../late-chip';
import StationCardAvatar from '../station-card-avatar';
import TrainTimeLabel from '../train-time-label';
import Twemoji from '../twemoji';

type TrainCardInput = {
  train: Train;
  startStop: TrainStop;
  destinationStop: TrainStop;
};

export default function TrainCard({
  train,
  startStop,
  destinationStop,
}: TrainCardInput) {
  const getTrainTypeLabel = () => {
    switch (train.type) {
      case TrainType.Bullet:
        return 'Baby Bullet';
      case TrainType.Limited:
        return 'Limited';
      case TrainType.Local:
        return 'Local';
      default:
        return train.type;
    }
  };

  const getTrainEmoji = () => {
    switch (train.type) {
      case TrainType.Bullet:
        return 'bullet_train';
      case TrainType.Limited:
        return 'high_speed_train';
      default:
        return 'light_rail';
    }
  };

  const renderCardHeaderIcon = () => {
    return (
      <Avatar>
        <Twemoji keyword={getTrainEmoji()} />
      </Avatar>
    );
  };

  const getTrainNumberLabel = () => {
    return (
      <React.Fragment>
        {`Train #${train.number}`}
        <LateChip trainNumber={train.number} stationName={startStop.station} />
      </React.Fragment>
    );
  };

  return (
    <Card className="train-card">
      <Link className="list-item-link" to={`/trains/${train.number}`}>
        <CardActionArea>
          <CardHeader
            avatar={renderCardHeaderIcon()}
            title={getTrainNumberLabel()}
            subheader={getTrainTypeLabel()}
            sx={{ p: 1 }}
          />
          <CardContent sx={{ p: 1 }}>
            <Stepper orientation="horizontal">
              <Step sx={{ pl: 0 }}>
                <StepLabel
                  StepIconComponent={() => <StationCardAvatar stopLetter="A" />}
                >
                  <DepartureTimeLabel
                    trainNumber={train.number}
                    stationName={startStop.station}
                  />
                </StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={() => <Fragment />}>
                  {
                    <TrainTimeLabel
                      trainNumber={train.number}
                      startStationName={startStop.station}
                      destinationStationName={destinationStop.station}
                    />
                  }
                </StepLabel>
              </Step>
              <Step sx={{ pr: 0 }}>
                <StepLabel
                  StepIconComponent={() => <StationCardAvatar stopLetter="B" />}
                >
                  <DepartureTimeLabel
                    trainNumber={train.number}
                    stationName={destinationStop.station}
                  />
                </StepLabel>
              </Step>
            </Stepper>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
