import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { pages } from '../../../constants';

export default function Navigation({ menuOpen, setMenuOpen }) {
  const renderNavigationItem = (page) => {
    return (
      <Link className="list-item-link" to={page.path}>
        <ListItem button onClick={(e) => setMenuOpen(false)}>
          <ListItemIcon>{page.navigationIcon}</ListItemIcon>
          <ListItemText>{page.headerText}</ListItemText>
        </ListItem>
      </Link>
    );
  };

  return (
    <SwipeableDrawer
      className="side-navigation"
      open={menuOpen}
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
    >
      <Box className="side-navigation-contents">
        <List>
          {renderNavigationItem(pages.schedule)}
          {renderNavigationItem(pages.settings)}
          {renderNavigationItem(pages.about)}
        </List>
      </Box>
    </SwipeableDrawer>
  );
}
