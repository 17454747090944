import React, { useState } from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { DateRange } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from '@mui/x-date-pickers';

type DatePickerInput = {
  date: Date;
  setDate: (date: Date) => void;
};

export default function DatePicker({ date, setDate }: DatePickerInput) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  if (setDate) {
    return (
      <React.Fragment>
        <Typography variant="caption">{date.toLocaleDateString()}</Typography>
        <IconButton
          size="large"
          edge="end"
          aria-label="date"
          onClick={(e) => setDatePickerOpen(true)}
        >
          <DateRange />
        </IconButton>
        <Dialog
          className="date-picker-dialog"
          open={datePickerOpen}
          onClose={(e) => setDatePickerOpen(false)}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateTimePicker
                disablePast={true}
                displayStaticWrapperAs="desktop"
                onChange={(newValue: any) => {
                  setDate(newValue);
                  setDatePickerOpen(false);
                }}
                openTo="day"
                value={date}
                views={['day']}
              />
            </LocalizationProvider>
          </Box>
        </Dialog>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="caption">{date.toLocaleDateString()}</Typography>
      <IconButton disabled={true} size="large" edge="end" aria-label="date">
        <DateRange />
      </IconButton>
    </React.Fragment>
  );
}
