import { useEffect, useState } from 'react';
import {
  Alert,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  MenuItem,
  Select,
} from '@mui/material';
import {
  getDefaultStartPoint,
  getDefaultDestination,
  setDefaultStartPoint,
  setDefaultDestination,
} from '../../../services/settingsService';
import { getStationNames } from '../../../services/stationService';
import StationCardAvatar from '../../components/station-card-avatar';

export default function Settings() {
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultStartPoint, setDefaultStartPointState] = useState(null);
  const [defaultDestination, setDefaultDestinationState] = useState(null);

  useEffect(() => {
    setLoading(true);
    setErrorMessage('');

    getStationNames()
      .then(async (stationNames) => {
        const startPoint = await getDefaultStartPoint();
        setDefaultStartPointState(startPoint);

        const destination = await getDefaultDestination();
        setDefaultDestinationState(destination);

        setStations(stationNames);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage(
          'Failed to load settings data. Please refresh to try again.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const setDefaultStartPointAndState = async (stationName) => {
    await setDefaultStartPoint(stationName);
    setDefaultStartPointState(stationName);
  };

  const setDefaultDestinationAndState = async (stationName) => {
    await setDefaultDestination(stationName);
    setDefaultDestinationState(stationName);
  };

  if (loading) {
    return <CircularProgress className="loading-indicator" size={80} />;
  }

  if (errorMessage) {
    return (
      <Alert severity="error" className="site-error-message">
        {errorMessage}
      </Alert>
    );
  }

  return (
    <List className="settings-list">
      <ListItem fullWidth>
        <Card className="settings-card">
          <CardHeader
            avatar={<StationCardAvatar stopLetter="A" />}
            title="Default Start Point"
            subheader="The default station selected as the start point when the app is opened."
          />
          <CardContent>
            <Select
              fullWidth
              value={defaultStartPoint}
              onChange={(e) => setDefaultStartPointAndState(e.target.value)}
              variant="standard"
            >
              {stations.map((stationName) => (
                <MenuItem value={stationName}>{stationName}</MenuItem>
              ))}
            </Select>
          </CardContent>
        </Card>
      </ListItem>
      <ListItem fullWidth>
        <Card className="settings-card">
          <CardHeader
            avatar={<StationCardAvatar stopLetter="B" />}
            title="Default Destination"
            subheader="The default station selected as the destination when the app is opened."
          />
          <CardContent>
            <Select
              fullWidth
              value={defaultDestination}
              onChange={(e) => setDefaultDestinationAndState(e.target.value)}
              variant="standard"
            >
              {stations.map((stationName) => (
                <MenuItem value={stationName}>{stationName}</MenuItem>
              ))}
            </Select>
          </CardContent>
        </Card>
      </ListItem>
    </List>
  );
}
