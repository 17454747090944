import { getStationNames } from './stationService';

const startPointKey = 'Settings_DefaultStartPoint';
const destinationKey = 'Settings_DefaultDestination';

const getDefaultStartPoint = async () => {
  const stationNames = await getStationNames();
  const storedSetting = localStorage.getItem(startPointKey);
  if (storedSetting && stationNames.includes(storedSetting)) {
    return storedSetting;
  }

  return stationNames[0];
};

const getDefaultDestination = async () => {
  const stationNames = await getStationNames();
  const storedSetting = localStorage.getItem(destinationKey);
  if (storedSetting && stationNames.includes(storedSetting)) {
    return storedSetting;
  }

  return 'San Jose Diridon';
};

const setDefaultStartPoint = (stationName: string) => {
  localStorage.setItem(startPointKey, stationName);
};

const setDefaultDestination = (stationName: string) => {
  localStorage.setItem(destinationKey, stationName);
};

export {
  getDefaultDestination,
  getDefaultStartPoint,
  setDefaultDestination,
  setDefaultStartPoint,
};
